import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Reward from 'react-rewards'
import { useScreen } from '../hooks'
import { useMedia } from '../hooks/useMedia'
import { answerThunk } from '../store/gameSlice'
import { RootState } from '../store/rootReducer'
import { theme } from '../theme'
import { IOption } from '../types'

export const Option = (props: IOption) => {
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)
  const rewardRef = useRef<any>(null)
  const { answer } = useSelector((state: RootState) => state.game)
  const [backgroundColor, setBackgroundColor] = useState('#121212')
  const [isDisabled, setIsDisabled] = useState(false)
  const screen = useScreen()
  const dispatch = useDispatch()

  const answerJSON = JSON.stringify(answer)
  useEffect(() => {
    if (answer?.spotifyTrackId === props.spotifyTrackId) {
      if (answer?.isCorrect) {
        setBackgroundColor('#2E7D32')
        rewardRef.current && rewardRef.current.rewardMe()
      } else {
        setBackgroundColor('#c62828')
        rewardRef.current && rewardRef.current!.punishMe()
      }
    }
  }, [answerJSON, answer, props.spotifyTrackId])

  useEffect(() => {
    setBackgroundColor('#121212')
    setIsDisabled(false)
  }, [props.spotifyTrackId])

  function handleClick() {
    if (isDisabled) return
    setIsDisabled(true)
    dispatch(answerThunk(props.spotifyTrackId))
  }

  const style: CSSProperties = {
    width: '100%',
    fontFamily: theme(screen).fontFamily.contentBold,
    fontSize: isDesktop ? '1em' : '0.9em',
    padding: '10px 40px',
    margin: '5px',
    textAlign: 'center',
    background: backgroundColor,
    borderRadius: '10px',
    color: 'white',
    cursor: 'pointer',
  }

  return (
    <div onClick={handleClick} style={style} key={props.spotifyTrackId} className="disable-select">
      <Reward ref={rewardRef} type="confetti" springAnimation={false}>
        {props.full_title}
      </Reward>
    </div>
  )
}
