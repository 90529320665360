import React from 'react'
import { useSelector } from 'react-redux'
import { LikeIcon, Option } from '.'
import { useMedia } from '../hooks/useMedia'
import { RootState } from '../store/rootReducer'
import { Flex } from './shared'

export const Answers = () => {
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)

  const { options } = useSelector((state: RootState) => state.game)
  if (!options) {
    return (
      <Flex width="auto" height="auto">
        Something went wrong, try refreshing{' '}
        <span role="img" aria-label="embarrassed emoji">
          😳
        </span>
      </Flex>
    )
  }

  return (
    <Flex
      flexDirection="column"
      width="auto"
      height="auto"
      className={`answers-container ${isDesktop ? 'desktop' : 'mobile'}`}
    >
      <Flex justifyContent="center">
        <LikeIcon {...options[0]} />
        <Option {...options[0]} />
      </Flex>
      <Flex justifyContent="center">
        <LikeIcon {...options[1]} />
        <Option {...options[1]} />
      </Flex>
      <Flex justifyContent="center">
        <LikeIcon {...options[2]} />
        <Option {...options[2]} />
      </Flex>
      <Flex justifyContent="center">
        <LikeIcon {...options[3]} />
        <Option {...options[3]} />
      </Flex>
    </Flex>
  )
}
