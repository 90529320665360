import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../services/ApiClient'
import { IOption, IThunk } from '../types'

export interface IGameState {
  _id?: string
  nextGameTime?: number
  currentTrack?: {
    _id: string
    spotifyTrackId: string
    preview_url: string
    album_image: string
    user: {
      first_name: string
      profile_image: string
      score: number
    }
    createdAt: number
  }
  options?: IOption[]
  answer?: {
    isCorrect: boolean
    spotifyTrackId: string
  }
}

const initialState: IGameState = {}

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateGame(state, action: PayloadAction<IGameState>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    newGame(state, action: PayloadAction<IGameState['currentTrack']>) {
      return {
        ...initialState,
        ...action.payload,
      }
    },
  },
})

export const answerThunk = (spotifyTrackId: string): IThunk => async (dispatch, state) => {
  try {
    const { game } = state()
    const gameId = game._id
    const client = new ApiClient()
    const { data } = await client.post(`games/${gameId}/answer`, { body: { spotifyTrackId } })

    return dispatch(
      updateGame({
        ...game,
        answer: {
          isCorrect: data.isAnswerCorrect,
          spotifyTrackId: data.spotifyTrackId,
        },
      })
    )
  } catch (error) {
    console.log('error', error)
  }
}

export const { newGame, updateGame } = gameSlice.actions

export default gameSlice.reducer
