import { Action, ThunkAction } from '@reduxjs/toolkit'
import { RootState } from './store/rootReducer'

export interface IScreen {
  width: number
  height: number
  orientation: 'landscape' | 'portrait'
  device: 'mobile' | 'tablet' | 'desktop'
}

export enum BREAKPOINTS {
  XSMALL = 500,
  SMALL = 850,
  MEDIUM = 1020,
}

export type IThunk = ThunkAction<void, RootState, unknown, Action<string>>

export interface IOption {
  spotifyTrackId: string
  full_title: string
}

export enum MESSAGE_TYPE {
  USER = 'USER',
  NOTIFICATION = 'NOTIFICATION',
}

export interface IArtist {
  status: ARTIST_STATUS
  spotify: SpotifyApi.ArtistObjectFull
  userId: string
}

export enum ARTIST_STATUS {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}
