import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlbumArt, DoughnutTimer, MuteIcon, Player } from '.'
import { useMedia } from '../hooks/useMedia'
import { updatePlayer } from '../store/playerSlice'
import { RootState } from '../store/rootReducer'

export const Vinyl = () => {
  const { currentTrack } = useSelector((state: RootState) => state.game)
  const { isMuted } = useSelector((state: RootState) => state.player)
  const dispatch = useDispatch()
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)

  const audioRef = useRef<HTMLAudioElement>(null)

  function handleToggleMute() {
    if (audioRef && audioRef.current) {
      audioRef.current.muted = !isMuted
      dispatch(updatePlayer({ isMuted: !isMuted }))
    }
  }

  return (
    <div className={`vinyl-container vinyl-container-${isDesktop ? 'desktop' : 'mobile'}`}>
      <DoughnutTimer spotifyUrl={currentTrack?.preview_url || ''} />
      <AlbumArt onClick={handleToggleMute} currentTrack={currentTrack} />
      {isMuted && <MuteIcon />}
      <Player audioRef={audioRef} currentTrack={currentTrack} />
    </div>
  )
}
