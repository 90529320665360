import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../services/ApiClient'
import { IThunk } from '../types'

interface IUser {
  first_name?: string
  score?: number
  spotify?: SpotifyApi.CurrentUsersProfileResponse
  artists?: SpotifyApi.ArtistObjectFull[]
}

const initialState: IUser = {}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<IUser>) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const authorizeCode = (authCode: string, authState: string): IThunk => async (dispatch, state) => {
  try {
    const body = {
      code: authCode,
      state: authState,
    }

    const apiClient = new ApiClient()
    const { data } = await apiClient.post(`users/auth`, { body })
    dispatch(updateUser(data.user))
    localStorage.setItem('vibzinJwtToken', data.token)
    localStorage.setItem('spotifyAccessToken', data.spotifyTokens.access_token)
    localStorage.setItem('spotifyRefreshToken', data.spotifyTokens.refresh_token)
  } catch (error) {
    console.log('Error: authorizeCode()', error)
  }
}

export const getMe = (): IThunk => async (dispatch, state) => {
  try {
    const apiClient = new ApiClient()
    const { data } = await apiClient.get('users/me')
    dispatch(updateUser(data.user))
  } catch (error) {
    console.log('Error: getMe()', error)
  }
}

export const { updateUser } = userSlice.actions

export default userSlice.reducer
