import { toast } from 'react-toastify'
import { LoginWithSpotify } from '../components'
import { NotificationContent } from '../components/NotificationContent'
import { getEnv, isProduction } from '../utils'

interface IPostOptions {
  body: Record<any, any> | string
}

export class ApiClient {
  readonly ROOT_API = isProduction ? getEnv('REACT_APP_PROD_API_ENDPOINT') : getEnv('REACT_APP_DEV_API_ENDPOINT')

  public async get(endpoint: string) {
    return fetch(`${this.ROOT_API}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('vibzinJwtToken'),
      },
    })
      .then((data) => {
        if (data.status === 401) {
          toast.error(LoginWithSpotify)
        }

        return data.json()
      })
      .catch((error) =>
        toast.error(
          NotificationContent({
            content: error.message,
          })
        )
      )
  }

  public async post(endpoint: string, { body }: IPostOptions) {
    return fetch(`${this.ROOT_API}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('vibzinJwtToken'),
      },
      body: JSON.stringify(body),
    })
      .then((data) => {
        if (data.status === 401) {
          toast.error(LoginWithSpotify)
        }

        return data.json()
      })
      .catch((error) =>
        toast.error(
          NotificationContent({
            content: error.message,
          })
        )
      )
  }
}
