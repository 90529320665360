import React from 'react'
import { Font } from '.'

interface IProps {
  onClick?: any
  content: any
}

export const NotificationContent = (props: IProps) => {
  return (
    <Font fontFamily="Ubuntu" onClick={props.onClick}>
      {props.content}
    </Font>
  )
}
