import React from 'react'
import { Flex } from '.'
import { useMedia } from '../hooks/useMedia'

interface IProps {
  artist: SpotifyApi.ArtistObjectFull
  selected: boolean
  onClick: (artistId: string) => void
}

export const ArtistSelect = (props: IProps) => {
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)
  const { artist, selected, onClick } = props
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="auto"
      height="auto"
      flexWrap="wrap"
      className={`artist-selection-artist-container artist-selection-artist-container-${
        isDesktop ? 'desktop' : 'mobile'
      } ${selected && 'selected'}`}
      onClick={() => onClick(artist.id)}
    >
      <div className="artist-selection-artist-text">{artist.name}</div>
      <div
        style={{ background: `url(${artist.images[0].url}) #333 center / cover` }}
        className={`artist-selection-artist-image`}
      />
    </Flex>
  )
}
