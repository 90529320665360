import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import ScrollToBottom from 'react-scroll-to-bottom'
import { Flex, Font, ProfileImage } from '.'
import { IChatMessage } from '../store/chatSlice'
import { RootState } from '../store/rootReducer'
import { MESSAGE_TYPE } from '../types'

export const ChatList = () => {
  const { messages } = useSelector((state: RootState) => state.chat)

  return (
    <ScrollToBottom className="chat-list" followButtonClassName="chat-follow-button">
      {messages.length ? messages.map((message) => renderMessage(message)).reverse() : <Font>No messages yet</Font>}
    </ScrollToBottom>
  )
}

function renderMessage(message: IChatMessage) {
  switch (message.type) {
    case MESSAGE_TYPE.USER:
      return (
        <Flex height="auto" key={message.createdAt} className="chat-message">
          <ProfileImage src={message.profile_image} size="50px" />
          <Flex className="chat-message-body" justifyContent="flex-start" flexDirection="column">
            <Font fontFamily="UbuntuBold">{message.first_name}</Font>
            <Font fontFamily="Ubuntu">{message.body}</Font>
            <Font fontFamily="UbuntuLight" fontSize="0.8em">
              {moment(message.createdAt).fromNow()}
            </Font>
          </Flex>
        </Flex>
      )

    case MESSAGE_TYPE.NOTIFICATION: {
      return (
        <Flex height="auto" key={message.createdAt} className="chat-message">
          <Flex className="chat-message-body" justifyContent="flex-start" flexDirection="column">
            <Font fontFamily="Ubuntu">{message.body}</Font>
            <Font fontFamily="UbuntuLight" fontSize="0.8em">
              {moment(message.createdAt).fromNow()}
            </Font>
          </Flex>
        </Flex>
      )
    }
  }
}
