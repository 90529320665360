import React from 'react'
import { IGameState } from '../store/gameSlice'

interface IProps {
  currentTrack: IGameState['currentTrack']
  onClick: any
}

export const AlbumArt = (props: IProps) => {
  return (
    <div className="album-art-container" onClick={props.onClick}>
      <div
        className="album-art rotating-vinyl"
        key={props.currentTrack?.album_image}
        style={{ background: `url(${props.currentTrack!.album_image})` }}
      />
    </div>
  )
}
