import React, { CSSProperties } from "react";

interface IProps {
  children: React.ReactNode;
  hide?: boolean
}

export const Container = (props: IProps) => {
  const style: CSSProperties = {
    width: "100%",
    height: "100%",
    margin: "0px auto",
    display: props.hide ? "none" : "block"
  };

  return <div style={style}>{props.children}</div>;
};
