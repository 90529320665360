import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../services/ApiClient'
import { IThunk } from '../types'

export interface ISpotifyState {
  authorizationUrl: string
}

const initialState: ISpotifyState = {
  authorizationUrl: '',
}

const spotifySlice = createSlice({
  name: 'spotify',
  initialState,
  reducers: {
    updateAuthorizationUrl(state, action: PayloadAction<string>) {
      return {
        ...state,
        authorizationUrl: action.payload,
      }
    },
  },
})

export const fetchAuthorizationUrl = (): IThunk => async (dispatch, state) => {
  try {
    const apiClient = new ApiClient()
    const { data } = await apiClient.get(`spotify/url`)
    dispatch(updateAuthorizationUrl(data.url))
  } catch (error) {
    console.log('Error: fetchAuthorizationUrl()', error)
  }
}

export const { updateAuthorizationUrl } = spotifySlice.actions

export default spotifySlice.reducer
