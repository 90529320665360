import { useHistory } from 'react-router-dom'
import { getEnv, isProduction } from '../utils'

const ROOT_API = isProduction ? getEnv('REACT_APP_PROD_API_ENDPOINT') : getEnv('REACT_APP_DEV_API_ENDPOINT')

interface IPostOptions {
  body: Record<any, any> | string
}

export const useApi = () => {
  const history = useHistory()

  const handleResponse = (data: Response) => {
    if (data.status === 401) {
      history.push('/')
    }
    return data.json()
  }

  async function get(endpoint: string) {
    return fetch(`${ROOT_API}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('vibzinJwtToken'),
      },
    }).then(handleResponse)
  }

  async function post(endpoint: string, { body }: IPostOptions) {
    return fetch(`${ROOT_API}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('vibzinJwtToken'),
      },
      body: JSON.stringify(body),
    }).then(handleResponse)
  }

  return { get, post }
}
