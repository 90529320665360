import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContentBox, Flex, HighScoreItem } from '../components'
import { useMedia } from '../hooks/useMedia'
import { RootState } from '../store/rootReducer'
import { showHighscore } from '../store/uiSlice'

interface IProps {
  className?: string | false
}

export const HighScores = (props: IProps) => {
  const highScores = useSelector((state: RootState) => state.highScores)
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)
  const dispatch = useDispatch()

  if (!highScores || !highScores.length) {
    return <ContentBox>No highscores yet</ContentBox>
  }

  return (
    <ContentBox
      className={`content-box ${isDesktop ? 'desktop' : 'mobile'} ${props.className && props.className}`}
      onClose={() => dispatch(showHighscore(false))}
    >
      <Flex style={{ overflow: 'scroll', marginBottom: '50px' }}>
        <ul style={{ listStyleType: 'none', width: '100%' }}>
          {highScores.map((highScore, index) => (
            <HighScoreItem className={`highscore-${index + 1}`} key={highScore._id} highScore={highScore} />
          ))}
        </ul>
      </Flex>
    </ContentBox>
  )
}
