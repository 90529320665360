import { useState, useEffect } from "react";
import { IScreen } from "../types";
import { orientationChanged, calculateDeviceFromWidth } from "../utils";

const getScreen = (): IScreen => ({
  width: window.innerWidth,
  height: window.innerHeight,
  orientation: window.innerWidth > window.innerHeight ? "landscape" : "portrait",
  device: calculateDeviceFromWidth(window.innerWidth),
});

export const useScreen = () => {
  const [screen, setScreen] = useState(getScreen());

  const updateScreen = () => {
    setScreen(getScreen());
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      orientationChanged().then(updateScreen);
    };

    window.addEventListener("resize", updateScreen);
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("resize", updateScreen);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return screen;
};
