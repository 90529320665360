import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserHighScore {
  _id: string;
  first_name: string;
  score: number;
  image: string;
}

const initialState: IUserHighScore[] = [];

const highScoreSlice = createSlice({
  name: "highScore",
  initialState,
  reducers: {
    updateHighScores(state, action: PayloadAction<IUserHighScore[]>) {
      return action.payload;
    },
  },
});

export const { updateHighScores } = highScoreSlice.actions;

export default highScoreSlice.reducer;
