import React from 'react'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from '.'
import { RootState } from '../store/rootReducer'
import { likeTrack, unlikeTrack } from '../store/trackSlice'

interface IProps {
  spotifyTrackId: string
  full_title: string
}

export const LikeIcon = (props: IProps) => {
  const { spotifyTrackId, full_title } = props
  const dispatch = useDispatch()
  const { likedTracks } = useSelector((state: RootState) => state.track)

  if (!spotifyTrackId) return <></>

  return likedTracks.includes(spotifyTrackId) ? (
    <Flex
      alignItems="center"
      className="like-icon"
      width="auto"
      height="auto"
      onClick={() => dispatch(unlikeTrack({ spotifyTrackId, full_title }))}
    >
      <AiFillHeart size="2em" />
    </Flex>
  ) : (
    <Flex
      alignItems="center"
      className="like-icon"
      width="auto"
      height="auto"
      onClick={() => dispatch(likeTrack({ spotifyTrackId, full_title }))}
    >
      <AiOutlineHeart size="2em" />
    </Flex>
  )
}
