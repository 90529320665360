import React from 'react'
import { Flex } from '../components'
import { useMedia } from '../hooks/useMedia'

interface IProps {
  children: React.ReactNode
  onClick?: any
  className?: any
}

export const MenuItem = (props: IProps) => {
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)

  return (
    <Flex
      onClick={props.onClick}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      className={`menu-item menu-item-${isDesktop ? `desktop` : `mobile`} ${props.className}`}
    >
      {props.children}
    </Flex>
  )
}
