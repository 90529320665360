import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IPlayerState {
  volume: number
  isMuted: boolean
  isPlaying: boolean
  audioRef: any
}

const initialState: IPlayerState = {
  volume: 0.5,
  isMuted: true,
  isPlaying: true,
  audioRef: null,
}

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    updatePlayer(state, action: PayloadAction<Partial<IPlayerState>>) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { updatePlayer } = playerSlice.actions

export default playerSlice.reducer
