import moment from 'moment'
import React from 'react'
import { Flex, Font, ProfileImage } from '.'
import { useMedia } from '../hooks/useMedia'
import { IGameState } from '../store/gameSlice'

interface IProps {
  track: IGameState['currentTrack']
}

export const TrackInfo = (props: IProps) => {
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)

  return (
    <Flex
      height="auto"
      width="auto"
      alignItems="center"
      className={`track-info-container ${isDesktop ? 'desktop' : 'mobile'}`}
    >
      <ProfileImage key={props.track?.user.profile_image} src={props.track?.user.profile_image || ''} size="40px" />
      <div>
        <Font margin="5px" fontSize={isDesktop ? '1em' : '0.8em'} fontFamily="Ubuntu" className="track-info-text">
          Track added by <span className="bold">{props.track?.user.first_name}</span>
        </Font>
        <Font margin="5px" fontFamily="UbuntuLight" fontSize={isDesktop ? '1em' : '0.6em'}>
          {moment(props.track?.createdAt).fromNow()}
        </Font>
      </div>
    </Flex>
  )
}
