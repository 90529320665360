import React, { RefObject, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IGameState } from '../store/gameSlice'
import { updatePlayer } from '../store/playerSlice'
import { RootState } from '../store/rootReducer'

interface IProps {
  audioRef: RefObject<HTMLAudioElement>
  currentTrack: IGameState['currentTrack']
}

export const Player = (props: IProps) => {
  const { currentTrack } = props
  const dispatch = useDispatch()
  const player = useSelector((state: RootState) => state.player)

  useEffect(() => {
    if (props.audioRef.current) {
      props.audioRef.current.volume = player.volume
      props.audioRef.current.muted = player.isMuted

      if (props.audioRef.current.src !== currentTrack!.preview_url) {
        props.audioRef.current.src = currentTrack!.preview_url
      }
    }
  }, [currentTrack, player.isPlaying, player.isMuted, player.volume, props.audioRef])

  function handleVolumeChange() {
    if (props.audioRef.current) {
      dispatch(updatePlayer({ volume: props.audioRef.current.volume }))
      dispatch(updatePlayer({ isMuted: props.audioRef.current.muted }))
    }
  }

  return (
    <audio ref={props.audioRef} autoPlay onVolumeChangeCapture={handleVolumeChange}>
      <source src={currentTrack!.preview_url} />
    </audio>
  )
}
