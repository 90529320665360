import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ArtistSelect, Container, Flex, Font } from '../components'
import { useApi } from '../hooks/useApi'
import { RootState } from '../store/rootReducer'

export const ArtistsSelection = () => {
  const MAX_ARTISTS = 5
  const [selectedArtists, setSelectedArtists] = useState<string[]>([])
  const history = useHistory()
  const user = useSelector((state: RootState) => state.user)
  const { post } = useApi()
  useEffect(() => {
    if (!user.first_name) {
      history.push('/')
    }
  }, [history, user.first_name])

  function handleToggleSelectArtist(artistId: string) {
    if (selectedArtists?.includes(artistId)) {
      setSelectedArtists(selectedArtists.filter((selectedArtist) => selectedArtist !== artistId))
    } else {
      if (selectedArtists.length === MAX_ARTISTS) return
      setSelectedArtists([...selectedArtists, artistId])
    }
  }

  function getFullArtistsFromIds(artistIds: string[]) {
    return user.artists?.filter((artist) => artistIds.includes(artist.id))
  }

  async function handleSubmit() {
    await post('artists', { body: { artists: getFullArtistsFromIds(selectedArtists) } })
    history.push('/play')
  }

  return (
    <Container>
      <Flex width="auto" height="auto" flexDirection="column" alignItems="center" justifyContent="center">
        <Font className="artist-selection-title" fontFamily="Ubuntu" align="center">
          Choose which artists to bring to the game!
          <br />
          <span className="bold">({MAX_ARTISTS - selectedArtists.length} artists remaining)</span>
        </Font>
        <Flex width="auto" flexWrap="wrap" justifyContent="center">
          {user.artists?.map((artist) => (
            <ArtistSelect
              key={artist.id}
              artist={artist}
              selected={selectedArtists.includes(artist.id)}
              onClick={handleToggleSelectArtist}
            />
          ))}
        </Flex>
        <Flex alignItems="center" justifyContent="center" style={{ marginTop: '10px' }}>
          <a className="button-link secondary" href={'/play'}>
            Skip
          </a>
          <button className="button-link" onClick={handleSubmit}>
            Add Artists
          </button>
        </Flex>
      </Flex>
    </Container>
  )
}
