import { IScreen, BREAKPOINTS } from "./types";

export const theme = (screen: IScreen) => ({
  colours: {
    primary: "black",
    secondary: "white",
    background: "#fafafa",
    accent: "orange",
  },
  width: {
    video: "640px",
  },
  height: {
    video: "390px",
  },
  fontFamily: {
    title: "PlayfairDisplay",
    content: "Ubuntu",
    contentLight: "UbuntuLight",
    contentBold: "UbuntuBold",
  },
  fontSize: {
    xxlarge: screen.width > BREAKPOINTS.SMALL ? "7rem" : "3rem",
    xlarge: "4rem",
    large: "1.2rem",
    medium: "1rem",
    small: "0.8rem",
    xsmall: "0.6rem",
    xxsmall: "0.4rem",
  },
  letterSpacing: {
    small: "0.1rem",
  },
  spacing: {
    small: screen.width > BREAKPOINTS.SMALL ? "0.625em" : "0.8em",
    medium: screen.width > BREAKPOINTS.SMALL ? "0.9375em" : "1.875em",
    large: screen.width > BREAKPOINTS.SMALL ? "1.25em" : "2.5em",
    xlarge: screen.width > BREAKPOINTS.SMALL ? "3.125em" : "3.75em",
  },
});
