import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { LoginWithSpotify } from '../components'
import { NotificationContent } from '../components/NotificationContent'
import { ApiClient } from '../services/ApiClient'
import { IThunk } from '../types'

const initialState = {
  likedTracks: [''],
}

const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    addTrack(state, action: PayloadAction<string>) {
      return {
        ...state,
        likedTracks: [...state.likedTracks, action.payload],
      }
    },
    removeTrack(state, action: PayloadAction<string>) {
      return {
        ...state,
        likedTracks: state.likedTracks.filter((track) => track !== action.payload),
      }
    },
  },
})

export const likeTrack = ({
  spotifyTrackId,
  full_title,
}: {
  spotifyTrackId: string
  full_title: string
}): IThunk => async (dispatch, state) => {
  try {
    dispatch(addTrack(spotifyTrackId))
    const apiClient = new ApiClient()
    const refresh_token = localStorage.getItem('spotifyRefreshToken')
    if (!refresh_token) throw new Error('No refresh token in localStorage')
    await apiClient.post(`tracks/${spotifyTrackId}/like`, { body: { refresh_token } })
    toast.success(
      NotificationContent({
        content: `${full_title} added to your likes on Spotify`,
      })
    )
  } catch (error) {
    dispatch(removeTrack(spotifyTrackId))
    toast.error(LoginWithSpotify)
  }
}

export const unlikeTrack = ({
  spotifyTrackId,
  full_title,
}: {
  spotifyTrackId: string
  full_title: string
}): IThunk => async (dispatch, state) => {
  try {
    dispatch(removeTrack(spotifyTrackId))
    const apiClient = new ApiClient()
    const refresh_token = localStorage.getItem('spotifyRefreshToken')
    if (!refresh_token) throw new Error('No refresh token in localStorage')
    await apiClient.post(`tracks/${spotifyTrackId}/unlike`, { body: { refresh_token } })
    toast.success(
      NotificationContent({
        content: `${full_title} removed from your likes on Spotify`,
      })
    )
  } catch (error) {
    dispatch(addTrack(spotifyTrackId))
    toast.error(LoginWithSpotify)
  }
}

export const { addTrack, removeTrack } = trackSlice.actions

export default trackSlice.reducer
