import React, { useEffect } from 'react'
import Countdown from 'react-countdown'
import { useDispatch, useSelector } from 'react-redux'
import { Answers, Chat, Container, Flex, HighScores, MenuBar, TrackInfo, Vinyl } from '../components'
import { useApi } from '../hooks/useApi'
import { useMedia } from '../hooks/useMedia'
import { updateGame } from '../store/gameSlice'
import { RootState } from '../store/rootReducer'

function renderEmoji(seconds: number) {
  if (seconds <= 5) {
    return `😼`
  }

  if (seconds <= 10) {
    return `😸`
  }

  if (seconds <= 20) {
    return `😺`
  }

  if (seconds <= 30) {
    return `🙀`
  }
}

export const Game = () => {
  const dispatch = useDispatch()
  const { get } = useApi()

  useEffect(() => {
    async function getGameState() {
      const { data: games } = await get('games')
      const [game] = games
      const { nextGameTime } = game
      dispatch(updateGame({ nextGameTime }))
    }

    getGameState().catch((error) => {
      console.log('Error getGameState:', error)
    })

    get('messages').catch((error) => console.log('Error getMessages:', error))
    // eslint-disable-next-line
  }, [])

  const { currentTrack, nextGameTime } = useSelector((state: RootState) => state.game)
  const { chat, highscore } = useSelector((state: RootState) => state.ui)
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)
  return (
    <Container>
      {currentTrack ? (
        <Flex width="100%">
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent={'center'}
            height="auto"
            width="100%"
            style={{ marginBottom: !isDesktop ? '50px' : 0 }}
          >
            <Vinyl />
            <TrackInfo track={currentTrack} />
            <Answers />
          </Flex>
          <Chat className={!chat && 'hidden'} />
          <HighScores className={!highscore && 'hidden'} />
          <MenuBar />
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center">
          {nextGameTime && (
            <Countdown
              date={nextGameTime}
              renderer={({ seconds }) => {
                return (
                  <div>
                    <span>Next round starting in:</span>
                    <br />
                    <br />
                    <span className="countdown">{seconds}</span>
                    <br />
                    <span className="countdown-emoji">{renderEmoji(seconds)}</span>
                  </div>
                )
              }}
            />
          )}
        </Flex>
      )}
    </Container>
  )
}
