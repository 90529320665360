import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import socketIOClient from 'socket.io-client'
import { NotificationContent } from '../components/NotificationContent'
import { useScreen } from '../hooks'
import { Callback, Faq, Game, Landing } from '../pages'
import { updateMessages } from '../store/chatSlice'
import { newGame } from '../store/gameSlice'
import { updateHighScores } from '../store/highScoreSlice'
import { theme } from '../theme'
import { IScreen } from '../types'
import { getEnv, isProduction } from '../utils'
import { ArtistsSelection } from './ArtistsSelection'

export const App = () => {
  const screen: IScreen = useScreen()
  const dispatch = useDispatch()

  useEffect(() => {
    // TODO: Give this a proper type
    let timeout: any
    const socket = socketIOClient(
      isProduction ? getEnv('REACT_APP_PROD_SOCKET_ENDPOINT') : getEnv('REACT_APP_DEV_SOCKET_ENDPOINT')
    )

    socket.on('highScores', (data: any) => {
      const highScores = JSON.parse(data)
      dispatch(updateHighScores(highScores))
    })

    socket.on('chat', (data: any) => {
      const messages = JSON.parse(data)
      dispatch(updateMessages(messages))
    })

    socket.on('gameUpdates', (data: any) => {
      const game = JSON.parse(data)
      dispatch(newGame(game))
    })

    socket.on('notifications', (data: any) => {
      const { level, message } = JSON.parse(data)
      switch (level) {
        case 'info':
          return toast.info(
            NotificationContent({
              content: message,
            })
          )
        case 'error':
          return toast.error(
            NotificationContent({
              content: message,
            })
          )
        default:
          return toast.dark(
            NotificationContent({
              content: message,
            })
          )
      }
    })

    return () => {
      socket.disconnect()
      clearTimeout(timeout)
    }
  }, [dispatch])

  const style = {
    width: '100%',
    height: '100%',
    backgroundColor: theme(screen).colours.background,
  }

  return (
    <div style={style}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/faq">
            <Faq />
          </Route>
          <Route exact path="/artists">
            <ArtistsSelection />
          </Route>
          <Route exact path="/play">
            <Game />
          </Route>
          <Route path="/callback">
            <Callback />
          </Route>
        </Switch>
      </Router>
      <ToastContainer position="top-right" autoClose={5000} pauseOnFocusLoss={false} />
    </div>
  )
}
