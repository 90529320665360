import React, { CSSProperties } from 'react'

interface IProps {
  align?: 'left' | 'center' | 'right'
  fontFamily?: string
  fontSize?: string
  letterSpacing?: string
  color?: string
  margin?: string
  className?: string
  onClick?: any
  children: React.ReactNode
}

export const Font = (props: IProps) => {
  const style: CSSProperties = {
    fontFamily: props.fontFamily || 'inherit',
    fontSize: props.fontSize || 'inherit',
    color: props.color || 'inherit',
    margin: props.margin || '0px',
    letterSpacing: props.letterSpacing || '0rem',
    textAlign: props.align || 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  return (
    <div className={props.className} style={style} onClick={props.onClick}>
      {props.children}
    </div>
  )
}
