import { configureStore } from "@reduxjs/toolkit"
import { rootReducer } from "./rootReducer"

export const store = configureStore({
    reducer: rootReducer
})

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
    (module as any).hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default
        store.replaceReducer(newRootReducer)
    })
}