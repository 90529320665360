import React, { CSSProperties } from 'react'
import { theme } from '../theme'
import { useScreen } from '../hooks'
import { ProfileImage } from './ProfileImage'
import { Flex } from './shared'

interface IProps {
  className?: string
  highScore: {
    image: string
    first_name: string
    score: number
  }
}

export const HighScoreItem = (props: IProps) => {
  const screen = useScreen()

  const style: CSSProperties = {
    padding: theme(screen).spacing.small,
  }

  return (
    <li style={style}>
      <Flex justifyContent="space-between" alignItems="center">
        <ProfileImage className={props.className} src={props.highScore.image} size="50px" />
        <div style={{ width: '140px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.highScore.first_name}</div>
        <div style={{ width: '50px' }}>{props.highScore.score}</div>
      </Flex>
    </li>
  )
}
