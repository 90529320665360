import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMedia } from '../hooks/useMedia'
import { messageThunk } from '../store/chatSlice'

export const ChatInput = () => {
  const dispatch = useDispatch()
  const [input, setInput] = useState('')
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== 'Enter') {
      return
    }
    dispatch(messageThunk(input))
    setInput('')
  }

  return (
    <input
      type="text"
      className="chat-input"
      placeholder="Enter a message"
      value={input}
      onChange={(e) => setInput(e.currentTarget.value)}
      onKeyPress={handleKeyPress}
      autoFocus={isDesktop ? true : false}
    />
  )
}
