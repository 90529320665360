import React, { CSSProperties } from 'react'
import { FaTimes } from 'react-icons/fa'
import { Flex } from '.'
import { useScreen } from '../hooks'
import { theme } from '../theme'

interface IProps {
  width?: string
  height?: string
  style?: CSSProperties
  className?: string
  children: React.ReactNode
  onClose?: () => void
}

export const ContentBox = (props: IProps) => {
  const screen = useScreen()

  const style: CSSProperties = {
    background: theme(screen).colours.primary,
    color: theme(screen).colours.background,
    fontFamily: theme(screen).fontFamily.content,
    padding: '10px',
    textAlign: 'center',
    minWidth: props.width || 'auto',
    minHeight: props.height || 'auto',
    overflow: 'hidden',
  }

  return (
    <div style={style} className={props.className}>
      <Flex className="close-button" width="auto" height="auto" justifyContent="flex-end" onClick={props.onClose}>
        <FaTimes size="2em" />
      </Flex>
      {props.children}
    </div>
  )
}
