import React from 'react'
import { FaVolumeMute } from 'react-icons/fa'
import { Flex } from '.'

export const MuteIcon = () => {
  return (
    <Flex className="vinyl-mute-container" alignItems="center" justifyContent="center">
      <Flex className="vinyl-mute-icon" alignItems="center" justifyContent="center">
        <FaVolumeMute className="pulsating" size="3em" />
      </Flex>
    </Flex>
  )
}
