import React, { CSSProperties } from 'react'

interface IProps {
  flexDirection?: CSSProperties['flexDirection']
  alignItems?: CSSProperties['alignItems']
  justifyContent?: CSSProperties['justifyContent']
  alignSelf?: CSSProperties['alignSelf']
  flexWrap?: CSSProperties['flexWrap']
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  style?: CSSProperties
  className?: string
  onClick?: any
  children: React.ReactNode
}

export const Flex = (props: IProps) => {
  const style: CSSProperties = {
    display: 'flex',
    flexDirection: props.flexDirection || 'row',
    alignItems: props.alignItems || 'normal',
    justifyContent: props.justifyContent || 'normal',
    width: props.width || '100%',
    height: props.height || '100%',
    flexWrap: props.flexWrap || 'nowrap',
    ...props.style,
  }

  return (
    <div style={style} className={props.className} onClick={props.onClick}>
      {props.children}
    </div>
  )
}
