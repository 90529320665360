import { combineReducers } from '@reduxjs/toolkit'
import chatReducer from './chatSlice'
import gameReducer from './gameSlice'
import highScoreReducer from './highScoreSlice'
import playerReducer from './playerSlice'
import spotifyReducer from './spotifySlice'
import trackReducer from './trackSlice'
import uiReducer from './uiSlice'
import userReducer from './userSlice'

export const rootReducer = combineReducers({
  chat: chatReducer,
  game: gameReducer,
  highScores: highScoreReducer,
  player: playerReducer,
  spotify: spotifyReducer,
  track: trackReducer,
  user: userReducer,
  ui: uiReducer,
})

export type RootState = ReturnType<typeof rootReducer>
