import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useMedia } from '../hooks/useMedia'

interface IProps {
  spotifyUrl: string
}

export const DoughnutTimer = (props: IProps) => {
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)
  return (
    <div style={{ position: 'relative', zIndex: 1, pointerEvents: 'none' }}>
      <CountdownCircleTimer
        key={props.spotifyUrl}
        isPlaying
        colors="#FFA500"
        size={isDesktop ? 250 : 200}
        strokeWidth={isDesktop ? 25 : 15}
        duration={30}
      />
    </div>
  )
}
