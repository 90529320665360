import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiClient } from '../services/ApiClient'
import { IThunk, MESSAGE_TYPE } from '../types'

export interface IChatMessage {
  type: MESSAGE_TYPE
  body: string
  first_name: string
  profile_image: string
  owner_id: string
  createdAt: number
}

export interface IChatState {
  messages: IChatMessage[]
}

const initialState: IChatState = {
  messages: [],
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    updateMessages(state, action: PayloadAction<IChatMessage[]>) {
      state.messages = action.payload
    },
  },
})

export const messageThunk = (body: string): IThunk => async () => {
  try {
    const client = new ApiClient()
    await client.post('messages', { body: { body } })
  } catch (error) {
    console.log('error', error)
  }
}

export const { updateMessages } = chatSlice.actions

export default chatSlice.reducer
