import React from 'react'
import { NotificationContent } from './NotificationContent'

export const LoginWithSpotify = () => (
  <NotificationContent
    onClick={() => (window.location.href = '/')}
    content={
      <div>
        Login with Spotify to play <br /> (it's free to create an account)
      </div>
    }
  />
)
