import React from 'react'
import { useDispatch } from 'react-redux'
import { ChatInput, ChatList, ContentBox } from '../components'
import { useMedia } from '../hooks/useMedia'
import { showChat } from '../store/uiSlice'

interface IProps {
  className?: string | false
}

export const Chat = (props: IProps) => {
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)
  const dispatch = useDispatch()

  return (
    <ContentBox
      className={`content-box ${isDesktop ? 'desktop' : 'mobile'} ${props.className && props.className}`}
      onClose={() => dispatch(showChat(false))}
    >
      <ChatList />
      <ChatInput />
    </ContentBox>
  )
}
