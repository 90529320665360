import qs from 'qs'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, Flex, Loader } from '../components'
import { RootState } from '../store/rootReducer'
import { authorizeCode } from '../store/userSlice'

export const Callback = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state: RootState) => state.user)
  const { search } = useLocation()
  const { code, state } = qs.parse(search.replace('?', ''))

  useEffect(() => {
    dispatch(authorizeCode(code as string, state as string))
  }, [code, dispatch, state])

  useEffect(() => {
    if (user.spotify?.id) {
      user.artists?.length ? history.push('/artists') : history.push('/play')
    }
  }, [user, history])

  return (
    <Container>
      <Flex alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    </Container>
  )
}
