import React from 'react'
import { BsFillChatFill } from 'react-icons/bs'
import { FaTrophy } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Font, MenuItem } from '../components'
import { useMedia } from '../hooks/useMedia'
import { RootState } from '../store/rootReducer'
import { showChat, showHighscore } from '../store/uiSlice'

export const MenuBar = () => {
  const dispatch = useDispatch()
  const { chat, highscore } = useSelector((state: RootState) => state.ui)
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)

  return (
    <Flex
      className={`menu-bar menu-bar-${isDesktop ? `desktop` : `mobile`}`}
      flexDirection={isDesktop ? 'column' : 'row'}
    >
      <MenuItem className={chat && 'menu-item-active '} onClick={() => dispatch(showChat(!chat))}>
        <BsFillChatFill size={isDesktop ? '2em' : '1.5em'} />
        <Font>Chat</Font>
      </MenuItem>
      <MenuItem className={highscore && 'menu-item-active '} onClick={() => dispatch(showHighscore(!highscore))}>
        <FaTrophy size={isDesktop ? '2em' : '1.5em'} />
        <Font>Highscores</Font>
      </MenuItem>
    </Flex>
  )
}
