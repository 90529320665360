import React, { CSSProperties } from 'react'
import { Flex, Font } from '.'

interface IProps {
  src: string
  size?: string
  className?: string
}

export const ProfileImage = (props: IProps) => {
  const style: CSSProperties = {
    minWidth: props.size || '75px',
    minHeight: props.size || '75px',
    width: props.size || '75px',
    height: props.size || '75px',
    borderRadius: '100%',
    background: `orange url(${props.src})`,
    backgroundSize: props.size ? `${props.size} ${props.size}` : `75px 75px`,
  }

  return (
    <Flex justifyContent="center" alignItems="center" className={props.className} style={style}>
      {!props.src && (
        <Font fontFamily="UbuntuBold" fontSize="1.5em">
          V
        </Font>
      )}
    </Flex>
  )
}
