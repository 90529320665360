import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import { Flex } from '../components'

export const Faq = () => {
  return (
    <Flex className="faq-container" flexDirection="column" alignItems="center" justifyContent="center" width="90%">
      <Accordion preExpanded={['first']}>
        <AccordionItem uuid="first">
          <AccordionItemHeading>
            <AccordionItemButton>What is Vibzin?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Vibzin is a fun way to share and discover music with friends as you try to identify the track playing
              faster than everyone else
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Where does the music come from?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>The pool of music is built upon every player's most played artists from Spotify</p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Which personal data do you store?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              Your Spotify data for most played artists is used to enhance the game's experience also your first name is
              used to uniquely identity you in-game
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Do I need Spotify to use Vibzin?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>
              To take part in the game you will need a Spotify account (it's free), however you can decide to Watch
              Anonymously without a Spotify account
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <a className="button-link" href="/">
        OK, let's play!
      </a>
    </Flex>
  )
}
