import React, { CSSProperties, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, Flex, Font } from '../components'
import { useMedia } from '../hooks/useMedia'
import { RootState } from '../store/rootReducer'
import { fetchAuthorizationUrl } from '../store/spotifySlice'

export const Landing = () => {
  const isDesktop = useMedia(['(min-width: 1500px)', '(min-width: 600px)'], [true, false], false)
  const dispatch = useDispatch()
  const { authorizationUrl } = useSelector((state: RootState) => state.spotify)

  useEffect(() => {
    dispatch(fetchAuthorizationUrl())
    toast.dark(
      <span style={{ fontFamily: 'Ubuntu' }}>
        Please email any feedback to{' '}
        <a className="bold text-link" href="mailto:feedback@julian.mp">
          feedback@julian.mp
        </a>
      </span>
    )
  }, [dispatch])

  return (
    <Container>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Font align="center" color="orange" fontSize="2em" fontFamily="UbuntuBold" margin="0 0 10px 0">
          Vibzin Beta
        </Font>
        <Flex
          flexDirection={`${isDesktop ? 'row' : 'column'}` as CSSProperties['flexDirection']}
          width="auto"
          height="auto"
          style={{ marginBottom: '10px' }}
        >
          <a className="button-link" href={authorizationUrl}>
            Login with Spotify
          </a>
          <a className="button-link secondary" href={'/play'}>
            Watch Anonymously
          </a>
        </Flex>
        <a className="text-link" href="/faq">
          What is this?
        </a>
      </Flex>
    </Container>
  )
}
