// The orientationchange event is triggered before
// changing the screens sizing, and also changing orientation
// doesn't trigger a resize event. Therefore this is needed

import { BREAKPOINTS } from "../types";

// to check for a screen size change for ${timeout} amount of frames
export const orientationChanged = () => {
  const timeout = 10;
  return new window.Promise((resolve) => {
    const go = (i: number, initialHeight: number) => {
      window.innerHeight !== initialHeight || i >= timeout
        ? resolve()
        : window.requestAnimationFrame(() => go(i + 1, initialHeight));
    };
    go(0, window.innerHeight);
  });
};

// This is by no means a good way of calculating a device...
// but it works for what we want to achieve
export const calculateDeviceFromWidth = (width: number): "mobile" | "tablet" | "desktop" => {
  switch (true) {
    case width < BREAKPOINTS.SMALL:
      return "mobile";
    case width > BREAKPOINTS.SMALL:
      return "desktop";
    default:
      return "desktop";
  }
};

export function getEnv(envKey: string): string {
  const env = process.env[envKey];
  if (!env) {
    throw new TypeError(`getEnv ${envKey} is not set`);
  }
  return env;
}

export const keyboardKeys = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const isProduction = process.env.NODE_ENV === "production" || process.env.REACT_APP_PROD === "true";
