import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IUIState {
  chat?: boolean
  highscore?: boolean
}

const initialState: IUIState = {
  chat: false,
  highscore: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showChat(state, action: PayloadAction<boolean>) {
      state.chat = action.payload
      state.highscore = action.payload && false
    },
    showHighscore(state, action: PayloadAction<boolean>) {
      state.highscore = action.payload
      state.chat = action.payload && false
    },
  },
})

export const { showChat, showHighscore } = uiSlice.actions

export default uiSlice.reducer
